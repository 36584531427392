<template>
  <!-- <div  class="container"> -->
				<!--<div class="animated-parent">
					<h2>Hobbies</h2>
					<b-container fluid class="p-4 bg-dark">
						<b-row>
							<b-col>
							<b-img thumbnail fluid src="../assets/anime.jpg" alt="Image 1"></b-img>
							</b-col>
							<b-col>
							<b-img thumbnail fluid src="../assets/sport.jpg" alt="Image 2"></b-img>
							</b-col>
							<b-col>
							<b-img thumbnail fluid src="../assets/lecture.jpg" alt="Image 3"></b-img>
							</b-col>
						</b-row>
					</b-container>
					<div id="informations" v-for="hobbie in hobbies " :key="hobbie.nom">
						<p> {{hobbie.nom}} </p>
					</div>
				</div>
				</div>-->
				
		<div class="accordion container" role="tablist" id="presentation">
			<h3 align="center" class="informations"> Hobbies</h3>
			<p align="center"> Cliquez sur les titres pour avoir quelques exemples ! </p>
			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1 " role="tab">
					<b-button block v-b-toggle.accordion-1  class="hobbie-button">Animés</b-button>
				</b-card-header>
				<b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
				<!-- <b-card-body>
					<b-card-text>I start opened because <code>visible</code> is <code>true</code></b-card-text>
					<b-card-text>{{ text }}</b-card-text>
					</b-card-body>-->
					<b-container fluid class="p-4 bg-white">
							<b-row>
								<b-col>
								<b-img thumbnail fluid src="../assets/naruto.jpg" alt="Image 1"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/onepiece.jpg" alt="Image 2"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/kuruko.jpg" alt="Image 3"></b-img>
								</b-col>
							</b-row>
						</b-container>
				</b-collapse>
			</b-card>

			<b-card no-body class="mb-1">
				<b-card-header header-tag="header" class="p-1" role="tab">
					<b-button block v-b-toggle.accordion-2 class="hobbie-button">Sports</b-button>
				</b-card-header>
				<b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
					<!--<b-card-body>
					<b-card-text>222222</b-card-text>
					</b-card-body>-->
					<b-row>
								<b-col>
								<b-img thumbnail fluid src="../assets/foot.jpg" alt="Image 1"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/tennis.jpg" alt="Image 2"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/course.jpg" alt="Image 3"></b-img>
								</b-col>
							</b-row>
							
				</b-collapse>
			</b-card>

			

			<b-card no-body class="mb-1">
			<b-card-header header-tag="header" class="p-1" role="tab">
				<b-button block v-b-toggle.accordion-3 class="hobbie-button">Lectures</b-button>
			</b-card-header>
			<b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
				<!--<b-card-body>
				<b-card-text>333333</b-card-text>
				</b-card-body>-->
				<b-row>
								<b-col>
								<b-img thumbnail fluid src="../assets/bcM.jpg" alt="Image 1"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/tintin.jpg" alt="Image 2"></b-img>
								</b-col>
								<b-col>
								<b-img thumbnail fluid src="../assets/livre.jpg" alt="Image 3"></b-img>
								</b-col>
							</b-row>
					
			</b-collapse>
			</b-card>
		</div>
  <!-- </div> -->
</template>

<script>
export default {
	name:'Hobbies',
	data(){
		return{
			hobbies:[
				{nom:"Regarder des animes"},
				{nom:"Lire"},
				{nom:"Faire du sport"}
			]
		}
	},
	methods:{
		getHobbies(){
			return this.hobbies;
		}
	}

}
</script>
